import { updateMetaData } from "./update-meta-data.util";

const { hostname } = location;
const environmentHostPrefix = {
    local: [
        '127.0.0.1',
        'localhost',
        'local.everlytic.com'
    ],
    devQa: 'devqa',
    qaCluster: 'qa-cluster',
    regressionQa: 'regression-qa'
} as const;
const environments = {
    local: 'Local',
    devQa: 'Dev QA',
    production: 'Production',
    qaCluster: 'QA Cluster',
    regressionQa: 'Regression QA'
} as const;
const regex = {
    localEnvironment: new RegExp(environmentHostPrefix.local.join('|'))
};

updateMetaData();

export function getLoggerConfig() {
    const { EV_CORE_META = {} } = window;
    const { e, c, u, i } = EV_CORE_META;
    const environment = hostname.match(regex.localEnvironment) ? environments.local
        : hostname.startsWith(environmentHostPrefix.devQa) ? environments.devQa
        : hostname.startsWith(environmentHostPrefix.qaCluster) ? environments.qaCluster
        : hostname.startsWith(environmentHostPrefix.regressionQa) ? environments.regressionQa
        : environments.production;
    const userId = `EVU_i:${i}_e:${e}_c:${c}_u:${u}`;
    const loggerConfig = {
        environments,
        environment,
        userId
    };

    return loggerConfig
}