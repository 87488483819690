const Selector = {
    meta: 'meta[name="landing-page-meta"]'
};

export function updateMetaData() {
    const { EV_CORE_META } = window;

    if (!EV_CORE_META) {
        setCoreMetaData();
    }

}

function setCoreMetaData() {
    const metaElement = document.querySelector<HTMLMetaElement>(Selector.meta);
    const metaData = {...metaElement?.dataset};
    const coreMetaData: CoreMetaData = {};

    for (const [key, value] of Object.entries(metaData)) {
        coreMetaData[key.toLowerCase()] = value || '-1';
    }

    window.EV_CORE_META = coreMetaData;
}

type CoreMetaData = Record<string, string>